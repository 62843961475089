<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="1000px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-toolbar dense>
            <v-toolbar-title>
              Propriétés du document : {{ document.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon :large="true" :color="document.isActive ? 'green' : 'red'">
              {{ document.isActive ? "mdi-file-document" : "mdi-file-cancel" }}
            </v-icon>
          </v-toolbar>

          <v-card-text>
            <v-card outlined class="mt-4">
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                    class="d-flex flex-column align-center"
                  >
                    <v-icon size="90">{{ document.file_icon }}</v-icon>
                    <h3>{{ document.size }} Ko</h3>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="12" sm="5" md="5">
                    <div>
                      <strong>Propriétaire :</strong> {{ document.owner }}
                    </div>
                    <div>
                      <strong>Date du Document :</strong>
                      {{ document.doc_date }}
                    </div>
                    <div>
                      <strong>Accessibilité :</strong>
                      <v-icon :color="document.ispublic ? 'green' : 'red'">
                        {{ document.ispublic ? "mdi-earth" : "mdi-lock" }}
                      </v-icon>
                      {{ document.ispublic ? "Public" : "Privé" }}
                    </div>
                    <div><strong>Version :</strong> {{ document.version }}</div>
                    <div>
                      <strong>Nombre de Révisions :</strong>
                      {{ document.nbrevisions }}
                    </div>
                  </v-col>

                  <v-col cols="12" sm="5" md="5">
                    <div><strong>Référence :</strong> {{ document.ref }}</div>
                    <div><strong>Titre :</strong> {{ document.title }}</div>
                    <div>
                      <strong>Nom du Fichier :</strong> {{ document.filename }}
                    </div>
                    <div>
                      <strong>Description :</strong> {{ document.description }}
                    </div>
                    <div>
                      <strong>Type de Document :</strong>
                      {{ document.doc_type_name }}
                    </div>
                    <div>
                      <strong>Numero Document :</strong>
                      {{ document.nodoc }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-tabs v-model="tab1" class="my-tabs">
              <!-- <v-tab key="1" href="#1"> Informations </v-tab> -->
              <v-tab
                key="2"
                href="#2"
                v-if="
                  document.create_uid == $store.state.me.id ||
                  $store.state.isadmin
                "
              >
                Permissions/Partage
              </v-tab>

              <v-tab key="3" href="#3" v-if="document.doc_type == -1">
                Revisions
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab1">
              <!-- <v-tab-item :value="'1'">
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="4" md="4">
                        Ref. {{ document.ref }}
                      </v-col>
                      <v-col cols="12" sm="8" md="8">
                        Titre: {{ document.title }}
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        Description: {{ document.description }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item> -->
              <v-tab-item :value="'2'">
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-row>
                          <!-- <v-col cols="12" sm="12" md="12">
                            <v-radio-group v-model="selectedAccessType" row>
                              <v-radio
                                label="Privé"
                                value="private"
                                :disabled="permissions_list.length > 0"
                              ></v-radio>
                              <v-radio label="Partagé" value="public"></v-radio>
                            </v-radio-group>
                          </v-col> -->
                          <v-col cols="12" sm="12" md="12">
                            <listitems
                              :list="permissions_list"
                              :qDelete="Qpermission_delete"
                              :headers="permission_headers"
                              :master="false"
                              :add="true"
                              :del="true"
                              :Update="true"
                              :showedit="false"
                              @open="OpenPermissionForm"
                              @rowselect="PermissionChange"
                              :exp_excel="false"
                              :key="kpermis"
                              :hdf="true"
                            >
                            </listitems>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'3'">
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <listitems
                          :list="revisions_list"
                          title="Revisions"
                          :headers="revisions_headers"
                          :master="true"
                          :add="true"
                          :del="false"
                          :Update="false"
                          @hrf="PreviewFile"
                          @open="OpenRevisionForm"
                          @rowselect="RevisionChange"
                          @col_btn2_click="downloadItem"
                          :showedit="false"
                          :exp_excel="true"
                          :key="krev"
                          :hdf="true"
                        >
                        </listitems>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <fileform
      :item="document"
      :items="revisions_list"
      :showForm="!isDocClose"
      :isRevision="isRevision"
      :key="ff"
      @close="isDocClose = true"
      @changed="data_change"
    >
    </fileform>
    <filepermission
      :item="permission"
      :items="permissions_list"
      :document="document"
      :showForm="!isPermClose"
      :key="fp"
      :groups="groups"
      :users="users.filter((elm) => elm.id != 1)"
      @close="isPermClose = true"
    >
    </filepermission>
    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>
<script>
let ends = [];
import DELETE_DOC_PERMISSION from "../graphql/File/DELETE_DOC_PERMISSION.gql";
import { api } from "print/data.js";
export default {
  components: {
    listitems: () => import("./ListItems.vue"),
    fileform: () => import("./FileForm.vue"),
    filepermission: () => import("./FilePermission.vue"),
    confirmdialog: () => import("./ConfirmDialog.vue"),
  },
  props: {
    showform: Boolean,
    document: Object,
    item: Object,
    ends: Array,
    groups: Array,
    users: Array,
  },
  data() {
    return {
      tab1: "1",
      valid: true,
      selitem: {},
      document_rev: {},
      permission: {},
      isDocClose: true,
      isPermClose: true,
      isRevision: false,
      ff: 900,
      fp: 1000,
      kpermis: 100,
      krev: 200,
      return_list: [],
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      selectedAccessType: "private",

      permission_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          sortable: false,
        },
        {
          text: "Group/Utilisateur",
          value: "permission_type",
          slot: "chip",
          color: "permission_color",
          selected: true,
        },
        {
          text: "Nom",
          value: "user_name",
          slot: "href",
          selected: true,
        },
        {
          text: "Lire",
          value: "canRead",
          slot: "checkbox",
          selected: true,
        },
        {
          text: "Ecrire",
          value: "canWrite",
          slot: "checkbox",
          selected: true,
        },
        {
          text: "Supprimer",
          value: "canDelete",
          slot: "checkbox",
          selected: true,
        },
      ],
      revisions_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          sortable: false,
        },
        {
          text: "Fichier",
          value: "filename",
          slot: "preview",
          preview: "preview",
          selected: true,
        },

        {
          text: "Type",
          value: "file_type",
          enum: "FILE_TYPE",
          selected: true,
        },
        {
          text: "Taille (ko)",
          value: "size",
          enum: "SIZE",
          align: "end",
          slot: "cur33",
          selected: true,
        },
        {
          text: "Date",
          value: "doc_date",
          slot: "date",
          selected: true,
        },
        {
          text: "Version",
          value: "version",
          selected: true,
        },
        {
          text: "Propriétaire",
          value: "owner",
          selected: false,
        },

        {
          text: "",
          slot: "col_btn2",
          icon: "mdi-download",
          selected: true,
          width: "10",
          tooltip: "Telecharger document",
          column1: "isurl",
          valeur1: 0,
          sortable: false,
        },
      ],
    };
  },
  watch: {
    permissions_list: {
      handler() {
        if (this.permissions_list) {
          this.selectedAccessType =
            this.permissions_list.length > 0 ? "public" : "private";
        }
      },
      deep: true,
    },
  },
  async created() {
    ends = this.ends;
    if (this.permissions_list)
      this.selectedAccessType =
        this.permissions_list.length > 0 ? "public" : "private";
    this.kpermis++;
    this.krev++;
  },
  computed: {
    showDetail() {
      return this.showform;
    },

    permissions_list() {
      let l = [];
      if (this.document)
        if (this.document.permissions) l = this.document.permissions;
      return l;
    },
    revisions_list() {
      let l = [];
      if (this.document)
        if (this.document.revisions) l = this.document.revisions;
      return l;
    },

    Qpermission_delete() {
      return DELETE_DOC_PERMISSION;
    },
  },
  methods: {
    data_change() {
      this.$emit("change");
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },

    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async PreviewFile(file) {
      if (file.isurl == 0) {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", api + file.url, true);
        xhr.responseType = "arraybuffer"; // Use arraybuffer instead of blob
        xhr.onprogress = function (pe) {
          this.doc_download = true;
          this.progress_value = parseInt(
            Math.round((pe.loaded / (file.c_size * 1000)) * 100)
          );
          if (this.progress_value >= 100) this.doc_download = false;
        };
        xhr.onload = function () {
          if (this.status === 200) {
            const JSZip = require("jszip");
            var zip = new JSZip();
            zip.loadAsync(this.response).then((contents) => {
              let promises = [];

              Object.keys(contents.files).forEach((filename) => {
                promises.push(
                  contents
                    .file(filename)
                    .async("nodebuffer")
                    .then((content) => {
                      return {
                        filename,
                        content,
                      };
                    })
                );
              });

              Promise.all(promises).then((files) => {
                files.forEach((file) => {
                  let ok = false;
                  let t;
                  if (ends) {
                    for (let index = 0; index < ends.length; index++) {
                      const element = ends[index];
                      if (file.filename.endsWith(element.ext)) {
                        ok = true;
                        t = element.app;
                        break;
                      }
                    }
                  }
                  if (ok) {
                    let blob = new Blob([file.content], {
                      type: t,
                    });

                    // Open the PDF in a new window
                    const pdfContentUrl = URL.createObjectURL(blob);
                    window.open(
                      pdfContentUrl,
                      "_blank",
                      "toolbar=yes,scrollbars=yes,resizable=yes,width=1000,height=1000"
                    );
                  }
                });
              });
            });
          }
        };
        xhr.send();
      } else window.open(file.filename, "_blank");
    },

    async downloadItem(file) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Télecharger ce document?"
        )
      ) {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", api + file.url, true);
        xhr.responseType = "blob";
        xhr.onprogress = function (pe) {
          this.doc_download = true;
          this.progress_value = parseInt(
            Math.round((pe.loaded / (file.c_size * 1000)) * 100)
          );
          if (this.progress_value >= 100) this.doc_download = false;
        }.bind(this);

        xhr.onload = function () {
          if (this.status === 200) {
            const JSZip = require("jszip");
            var zip = new JSZip();
            zip.loadAsync(this.response).then(function (contents) {
              Object.keys(contents.files).forEach(function (filename) {
                zip
                  .file(filename)
                  .async("nodebuffer")
                  .then(function (content) {
                    let blob = new Blob([content], {
                      type: "application/pdf",
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = file.filename;
                    link.click();
                  });
              });
            });
          }
        };
        xhr.send();
      }
    },
    close() {
      this.$emit("close");
    },

    OpenRevisionForm(item) {
      this.isRevision = true;
      this.document_rev = item;
      this.isDocClose = false;
      this.ff++;
    },
    RevisionChange(item) {
      this.document_rev = item;
    },
    OpenPermissionForm(item) {
      this.permission = item;
      this.isPermClose = false;
      this.fp++;
    },
    PermissionChange(item) {
      this.permission = item;
    },
  },
};
</script>
